import { MAX_NUMBER, RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { StepError } from '~/state/mainAppState';
import { RentalHistory, RentalApplicationErrorCode } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class ValidateRentalHistoryUseCase extends MagicUseCase {
  private nameRegex = /^[a-zA-Z\s]{2,150}$/;
  private addressRegex = /^(?!.*([&][#]))[A-Za-z0-9 #(),&.,'_+~/*-]*$/;
  private cityRegex = /^[A-Za-zÀ-ÿ '-.,]{2,27}$/;
  private usZipRegex = /^[0-9]{5}$/;
  private canZipRegex = /^[A-Za-z][0-9][A-Za-z][\s-]?[0-9][A-Za-z][0-9]$/;

  private validateAddress1 = (data?: RentalHistory) => {
    if (
      !data?.address?.streetAddress1 ||
      data?.address?.streetAddress1.length === 0 ||
      data?.address?.streetAddress1.length > 50 ||
      !this.addressRegex.test(data?.address?.streetAddress1)
    ) {
      return RentalApplicationErrorCode.InvalidRentalHistoryStreetAddress1;
    }
  };

  private validateAddress2 = (data?: RentalHistory) => {
    if (
      data?.address?.streetAddress2 &&
      (data?.address?.streetAddress2.length > 100 || !this.addressRegex.test(data?.address?.streetAddress2))
    ) {
      return RentalApplicationErrorCode.InvalidRentalHistoryStreetAddress2;
    }
  };

  private validateReasonForLeaving = (data?: RentalHistory) => {
    if (data?.reasonForLeaving && data?.reasonForLeaving.length > 500) {
      return RentalApplicationErrorCode.InvalidRentalHistoryReasonForLeaving;
    }
  };

  private validateCity = (data?: RentalHistory) => {
    if (
      !data?.address?.city ||
      data?.address?.city.length < 2 ||
      data?.address?.city.length > 27 ||
      !this.cityRegex.test(data?.address?.city)
    ) {
      return RentalApplicationErrorCode.InvalidRentalHistoryCity;
    }
  };

  private validateState = (data?: RentalHistory) => {
    if (!data?.address?.state || data?.address?.state.length !== 2) {
      return RentalApplicationErrorCode.InvalidRentalHistoryState;
    }
  };

  private validateZipCode = (data?: RentalHistory) => {
    if (!data?.address?.country || !data?.address?.zipCode) {
      return RentalApplicationErrorCode.InvalidRentalHistoryZipCode;
    }

    if (data?.address?.country === 'USA' && !this.usZipRegex.test(data?.address?.zipCode)) {
      return RentalApplicationErrorCode.InvalidRentalHistoryZipCode;
    } else if (data?.address?.country === 'CAN' && !this.canZipRegex.test(data?.address?.zipCode)) {
      return RentalApplicationErrorCode.InvalidRentalHistoryZipCode;
    }
  };

  private validateCountry = (data?: RentalHistory) => {
    if (!data?.address?.country || data?.address?.country.length < 3 || data?.address?.country.length > 25) {
      return RentalApplicationErrorCode.InvalidRentalHistoryCountry;
    }
  };

  private validateLandlordName = (data?: RentalHistory) => {
    if (data?.landlordName && !this.validateName(data?.landlordName)) {
      return RentalApplicationErrorCode.InvalidRentalHistoryLandlordName;
    }
  };

  private validateLandlordPhone = (data?: RentalHistory) => {
    if (data?.landlordPhone && !this.validatePhone(data?.landlordPhone)) {
      return RentalApplicationErrorCode.InvalidRentalHistoryLandlordPhone;
    }
    return undefined;
  };

  private validateName = (name: string | undefined): boolean => {
    if (!name) {
      return false;
    }

    return this.nameRegex.test(name);
  };

  private validatePhone = (phone: string | undefined): boolean => {
    if (!phone) {
      return false;
    }

    const digitsOnly = phone.replace(/\D+/g, '');
    if (/^(00|\+)/.test(phone)) {
      return phone.length > 3;
    } else {
      return digitsOnly.length === 10;
    }
  };

  private validateRent = (data?: RentalHistory) => {
    if (!data?.rent || data?.rent < 0 || data?.rent > MAX_NUMBER) {
      return RentalApplicationErrorCode.InvalidRentalHistoryRent;
    }
  };

  private validateMoveInDate = (data?: RentalHistory) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const moveInDate = data?.moveInDate || '';

    if (moveInDate.length === 0 || !dateRegex.test(moveInDate)) {
      return RentalApplicationErrorCode.InvalidRentalHistoryMoveInDate;
    }
  };

  private validateMoveOutDate = (data?: RentalHistory) => {
    if (!data?.moveOutDate) {
      return;
    }

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const moveOutDate = data?.moveOutDate || '';
    const moveInDate = data?.moveInDate || '';
    const moveOutDateObj = new Date(moveOutDate);
    const moveInDateObj = new Date(moveInDate);

    if (moveOutDate.length > 0 && !dateRegex.test(moveOutDate)) {
      return RentalApplicationErrorCode.InvalidRentalHistoryMoveOutDate;
    } else if (moveOutDate.length > 0 && dateRegex.test(moveOutDate) && dateRegex.test(moveInDate) && moveInDateObj >= moveOutDateObj) {
      return RentalApplicationErrorCode.InvalidRentalHistoryMoveOutDateEarlier;
    }
    return undefined;
  };

  private rules: Partial<Record<string, { validator: (data: any) => RentalApplicationErrorCode | undefined }>> = {
    streetAddress1: { validator: this.validateAddress1 },
    streetAddress2: { validator: this.validateAddress2 },
    reasonForLeaving: { validator: this.validateReasonForLeaving },
    city: { validator: this.validateCity },
    state: { validator: this.validateState },
    zipCode: { validator: this.validateZipCode },
    country: { validator: this.validateCountry },
    landlordName: { validator: this.validateLandlordName },
    landlordPhone: { validator: this.validateLandlordPhone },
    rent: { validator: this.validateRent },
    moveInDate: { validator: this.validateMoveInDate },
    moveOutDate: { validator: this.validateMoveOutDate },
  };

  protected async runLogic(params: { index: number; filedName: string }) {
    const { t } = useLocalization();
    const application = this.getState().user.rentalApplication.application;

    if (!application) {
      throw new Error(t(RENTAL_APPLICATION_NOT_FOUND));
    }

    this.getState().user.rentalApplication.errors = { ...this.getState().user.rentalApplication.errors };

    const rentalHistory = this.getState().user.rentalApplication.application?.residentialHistory;

    if (!this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY]) {
      this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] = [];
    } else {
      this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] = [
        ...(this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] as StepError[]),
      ];
    }

    if (!rentalHistory || rentalHistory.length === 0) {
      return;
    }

    const histories = params?.index ? [rentalHistory.at(params.index)] : rentalHistory;

    histories.forEach((history, historyIndex) => {
      const index = params?.index ?? historyIndex;

      if (params?.filedName) {
        (this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] as StepError[])[index] = {
          ...(this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] as StepError[])[index],
        };

        const stepError = (this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] as StepError[])[index];

        stepError[params.filedName] = this.rules[params.filedName]?.validator(history);
      } else {
        (this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] as StepError[])[index] = {};

        const stepError = (this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] as StepError[])[index];

        Object.keys(this.rules).forEach((key) => {
          const rule = this.rules[key];

          stepError[key] = rule?.validator(history);
        });
      }
    });
  }
}
