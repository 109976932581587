import { StepError } from '~/state/mainAppState';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class RemoveRentalHistoryErrorUseCase extends MagicUseCase {
  protected async runLogic(index: number) {
    if (!index) {
      return;
    }

    const rentalHistoryErrors = this.getState().user.rentalApplication.errors?.[RentalApplicationSteps.RENTAL_HISTORY];

    if (!rentalHistoryErrors || !rentalHistoryErrors.length) {
      return;
    }

    (this.getState().user.rentalApplication.errors[RentalApplicationSteps.RENTAL_HISTORY] as StepError[]).splice(index, 1);
  }
}
